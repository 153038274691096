import React, { useEffect, useState } from "react";
import { useWindowDimension } from "hooks/useWindowDimension";
import NewTicketModal from "./NewTicketModal";
import GigBlock from "./GigBlock";
import videoBgLaptop from "../assets/videos/monos-verde-grande.mov";
import { FaEye } from "react-icons/fa";

const performances = [
  {
    image: null,
    video: "https://www.youtube.com/embed/9GPv6ooRP2s",
    title: "House Sweet House # 02",
    content: `DubStudios co-founders ERMO and Lemond delivered an electrifying back-to-back performance at the second edition of House Sweet House, a local label event in Guadalajara.`,
    date: "November 30th, 2024",
  },
  {
    image: null,
    video: "https://www.instagram.com/p/C_HRkZ6xICA/embed",
    title: "House Sweet House # 01",
    content: `First edition of House Sweet House took place at C Garden, featuring an extraordinary lineup of local talent.`,
    date: "August 17th, 2024",
  },
  {
    image: null,
    date: "August 10th, 2024",
    video: "https://www.instagram.com/p/C-foQYbRuP2/embed",
    title: "Joiku Music @speakeasy",
    content: `ERMO B2B Lemond in the unique "El SpeakEasy" at Guadalajara Jalisco.`,
  },
];

export default function Performances() {
  const [width] = useWindowDimension();
  const [isOpen, setOpen] = useState(false);
  const [showPerformances, setShowPerformances] = useState(false);
  const [eyeOpacity, setEyeOpacity] = useState(1); // Controls eye opacity

  const handleTogglePerformances = () => {
    setEyeOpacity(0); // Start fading out

    setTimeout(() => {
      setShowPerformances((prev) => !prev); // Toggle performances
      setEyeOpacity(1); // Start fading back in
    }, 300); // Matches the fade-out duration
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div
      id="performances-page"
      name="performances-page"
      className="relative flex flex-col items-center bg-pure-black w-[100vw] z-10 scroll-snap-type-y-mandatory"
      style={{
        height: showPerformances ? "auto" : "100vh", // Adjust height when performances are shown
        marginTop: "75px", // Match NavBar height
      }}
    >
      {/* Background Video */}
<video
  className="absolute top-0 left-0 w-full h-full object-cover z-0"
  playsInline
  autoPlay
  muted
  loop
  style={{
    maxWidth: "100%",
    height: "auto",
    zIndex: -1,
  }}
>
  <source src={width > 768 ? videoBgLaptop : require("../assets/videos/monos-verde-chico.mp4")} type="video/mp4" />
</video>

     {/* Show Past Performances Button */}
{!showPerformances && (
  <button
    className="absolute left-1/2 transform -translate-x-1/2 font-bold bg-font-color-white bg-opacity-90 text-font-color-black hover:bg-opacity-40 font-jetbrains py-2 px-6 rounded-full hover:scale-105 transition-all duration-300"
    style={{
      zIndex: 10,
      opacity: eyeOpacity, // Smooth fade driven by state
      transition: "opacity 0.3s ease-in-out", // Smooth opacity transition
      top: "20%", // Static position for the button
    }}
    onClick={handleTogglePerformances}
  >
    Show Past Performances
  </button>
)}

{/* Eye Icon */}
{showPerformances && (
  <button
    className="absolute left-1/2 transform -translate-x-1/2 text-font-color-white hover:scale-110"
    style={{
      zIndex: 10,
      opacity: eyeOpacity, // Smooth fade driven by state
      transition: "opacity 0.3s ease-in-out", // Smooth opacity transition
      top: "1%", // Move closer to the NavBar (adjust this value as needed)
      marginBottom: "8px", // Add extra spacing below
    }}
    onClick={handleTogglePerformances}
  >
    <FaEye size={50} />
  </button>
)}


      {/* Performances Section */}
      <div
        className={`flex flex-col w-full h-auto relative ${
          showPerformances ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        style={{
          zIndex: 10,
          marginTop: showPerformances ? "120px" : "0px",
          transition: "opacity 0.3s ease-out",
        }}
      >
        {performances.map((gig, index) => (
          <div
            key={index}
            className="scroll-snap-align-start flex items-center justify-center"
            style={{
              height: "50vh",
              margin: "10px 0",
              transform: showPerformances ? "translateY(0)" : `translateY(-100px)`,
              opacity: showPerformances ? 1 : 0.1,
              transition: `transform 0.5s cubic-bezier(0.25, 1, 0.5, 1) ${
                index * 0.1
              }s, opacity 0.5s ease ${index * 0.1}s`,
            }}
          >
            <GigBlock
              image={gig.image}
              video={gig.video}
              title={gig.title}
              content={gig.content}
              date={gig.date}
            />
          </div>
        ))}
      </div>

      {/* Modal Section */}
      <NewTicketModal
        imgSrc={require("../assets/images/choflas-flyer.jpeg")}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}
