import { useWindowDimension } from "hooks/useWindowDimension";
import React from "react";
import videoBgMobile from "../assets/videos/hello-dub-chico.mp4";
import videoBgLaptop from "../assets/videos/hello-dub-grande.mp4";
import { ReactComponent as Logo } from "../assets/images/logo-slim.svg";

export const About = () => {
  const [width] = useWindowDimension();
  return (
    <div
      className="flex flex-col justify-center items-center mt-10 w-[100vw] h-[100vh] z-10 section scroll-snap-start"
      id="about-page"
    >
      {/* Background Video */}
            <video
        className="absolute left-0 w-full h-full object-cover z-0"
        playsInline
        autoPlay
        muted
        loop
        style={{
          maxWidth: "100%",
          height: "auto",
          zIndex: -1,
        }}
      >
        <source src={width > 768 ? videoBgLaptop : videoBgMobile} type="video/mp4" />
      </video>

      {/* Content */}
      <div className="flex flex-col items-center justify-start relative text-center m-10 bg-opacity-0 h-[100vh] w-[100vw] mx-auto rounded-xl z-10">
        <Logo
          style={{ width: "50px", fill: "#fefae0" }}
          className="mt-10 text-center items-center"
          fill="font-color-white"
        />
        <h1 className="text-bold mt-[100px] text-center font-jetbrains text-font-color-white text-3xl">
          Who Are We?
        </h1>
        <p className="text-bold leading-8 text-center m-2 justify-center font-jetbrains text-font-color-white text-m mt-10 md:text-xl">
          Dub Studios is a venue dedicated to blending the many facets of art. <br />
          <br />
          We believe that everyone has a creative side, and we are open to welcoming new talents and unique perspectives on art. <br />
          <br />
          To achieve this, we host events featuring a variety of themes and artistic expressions.
        </p>
        <p className="text-bold leading-8 text-center m-2 justify-center font-jetbrains text-font-color-white text-m mt-10 md:text-xl">
          Dub Studios is also home to a record label, owned and managed by the talented ERMO and Lemond. The label is committed to showcasing cutting-edge music and fostering a community of innovative artists.
        </p>
      </div>
    </div>
  );
};
