import './styles/index.scss'
import NavBar from './components/NavBar'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { About } from 'components/About'
import {
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import SuccessfulPayment from 'components/SuccessfulPayment'
import videoBgMobile from './assets/videos/monos-verde-chico.mp4'
import videoBgLaptop from './assets/videos/hello-dub-grande.mp4'
import Performances from 'components/Performances'
import { useWindowDimension } from 'hooks/useWindowDimension'
import ScrollButton from 'components/ScrollButton'
import Socials from 'components/Socials'
import './App.css';



function App() {

  const [width] = useWindowDimension()
  return (
    <>
        <Router>
          {width <= 768 ? (
            <video
              className='absolute w-[100%] h-[100vh] top-0 left-0 object-cover'
              playsInline
              autoPlay
              muted
              loop
              style={{ width: '100%', height: 'auto' }}
            >
              <source src={videoBgMobile} type='video/mp4' />
            </video>
          ) : (
            <video
              className='absolute w-[100%] h-[100vh] top-0 left-0 object-cover'
              playsInline
              autoPlay
              muted
              loop
              style={{ width: '100%', height: 'auto' }}
            >
              <source src={videoBgLaptop} type='video/mp4' />
            </video>
          )}

          <div className='w-[100%] h-[100vh] flex flex-col mx-auto items-center position-absolute'>
              <Routes>
                <Route
                  path='/'
                  element={
                    <div className='flex flex-col bg-pure-black'>
                      <NavBar />
                      <Performances />
                      <About />
                      <Socials/>
                      <ScrollButton />
                    </div>
                  }
                ></Route>
                <Route
                  path='/checkout'
                  element={<EmbeddedCheckout className='bg-white z-10' />}
                ></Route>
                <Route
                  path='/successful_payment'
                  element={<SuccessfulPayment />}
                ></Route>
              </Routes>
          </div>
        </Router>
    </>
  )
}

export default App
