import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/images/logo-slim.svg";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useWindowDimension } from "hooks/useWindowDimension";

const venue = "DubStudio";

export default function NavBar() {
  const [header, setHeader] = useState("");
  const [blink, setBlink] = useState(true);
  const [nav, setNav] = useState(false);
  const [width] = useWindowDimension();

  useEffect(() => {
    const timeout = setInterval(() => {
      setHeader(venue.slice(0, header.length + 1));
    }, 100);

    return () => clearInterval(timeout);
  }, [header]);

  useEffect(() => {
    const interval = setInterval(() => {
      setBlink(false);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div
      className="fixed top-0 left-0 w-full bg-pure-black bg-opacity-90 z-50 shadow-md flex flex-col items-center"
      style={{ height: "75px" }}
    >
      <div className="w-[96vw] md:w-full flex justify-between items-center h-full">
        {/* Header Section (Left-Aligned) */}
        <div className="flex items-center w-1/3">
          <LinkRouter to="/">
            <h1
              className={`z-[10000] text-font-color-green mx-4 font-jetbrains font-bold text-2xl md:text-4xl sm:text-4xl ${
                blink ? "blinking-cursor" : ""
              }`}
              style={{ whiteSpace: "nowrap" }}
            >
              {header}
            </h1>
          </LinkRouter>
        </div>

        {/* Logo Section (Centered) */}
        <div className="flex justify-center items-center w-1/3">
          <LinkScroll
            to="performances-page"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="cursor-pointer"
          >
            <Logo
              style={{
                width: "50px", // Adjust size of the logo
                height: "auto",
                fill: "#ffffff", // White logo
              }}
              className="hover:scale-110 transition-transform"
            />
          </LinkScroll>
        </div>

        {/* Navigation Links / Mobile Menu Icon (Right-Aligned) */}
        <div className="flex justify-end items-center w-1/3">
          <ul className="hidden md:flex">
            <LinkScroll
              to="performances-page"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="cursor-pointer p-4 opacity-85"
            >
              <p className="text-font-color-white font-jetbrains font-bold text-xl">
                Performances
              </p>
            </LinkScroll>
            <LinkScroll
              to="about-page"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="cursor-pointer p-4 opacity-85"
            >
              <p className="text-font-color-white font-jetbrains font-bold text-xl">
                About
              </p>
            </LinkScroll>
            <LinkScroll
              to="socials-page"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="cursor-pointer p-4 opacity-85"
            >
              <p className="text-font-color-white font-jetbrains font-bold text-xl">
                Socials
              </p>
            </LinkScroll>
          </ul>
          <div onClick={handleNav} className="block md:hidden">
            {nav ? (
              <AiOutlineClose style={{ color: "white" }} size={25} />
            ) : (
              <AiOutlineMenu style={{ color: "white" }} size={25} />
            )}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={
          nav
            ? "absolute left-0 top-[75px] w-[60%] h-screen bg-pure-black bg-opacity-90 z-40 border-r border-r-gray-100 ease-in duration-500"
            : "absolute left-[-100%] top-[75px] w-[60%] h-screen ease-in duration-500"
        }
      >
        <ul className="pt-24 uppercase">
          <LinkScroll
            to="performances-page"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="cursor-pointer p-4 opacity-85"
          >
            <p className="text-font-color-white font-jetbrains font-bold text-xl">
              Performances
            </p>
          </LinkScroll>
          <LinkScroll
            to="about-page"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="cursor-pointer p-4 opacity-85"
          >
            <p className="text-font-color-white font-jetbrains font-bold text-xl">
              About
            </p>
          </LinkScroll>
          <LinkScroll
            to="socials-page"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="cursor-pointer p-4 opacity-85"
          >
            <p className="text-font-color-white font-jetbrains font-bold text-xl">
              Socials
            </p>
          </LinkScroll>
        </ul>
      </div>
    </div>
  );
}
