const GigBlock = ({ className, image, video, title, content, date }) => {
  const embedVideoUrl = video ? video : null;

  return (
    <div
      className={`flex flex-col md:flex-row bg-pure-black text-font-color-white shadow-lg rounded-lg overflow-hidden m-6 w-[90%] h-full ${className}`}
      style={{ paddingLeft: '20px', paddingRight: '20px' }}
    >
      {/* Media Section */}
      <div className="w-full md:w-1/3 h-64 md:h-full overflow-hidden">
        {video ? (
          <iframe
            src={embedVideoUrl}
            title="YouTube Video Preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full h-full"
          ></iframe>
        ) : (
          <img
            src={image}
            alt="Gig"
            className="w-full h-full object-cover"
          />
        )}
      </div>

      {/* Info Section */}
      <div className="w-full md:w-2/3 h-full p-8 flex flex-col justify-start bg-buttons-color bg-opacity-90 overflow-y-auto">
        <h3 className="text-4xl font-jetbrains font-bold mb-2">{title}</h3>
        {date && (
          <p className="text-base text-buttons-color-light font-jetbrains italic mb-4">
            {date}
          </p>
        )}
        <hr className="border-t-2 border-font-color-white mb-4" />
        <p className="text-lg font-jetbrains">{content}</p>
      </div>
    </div>
  );
};

export default GigBlock;
