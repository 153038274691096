import React from 'react';
import { ReactComponent as SoundcloudIcon } from '../assets/images/soundcloud.svg';
import { ReactComponent as YoutubeIcon } from '../assets/images/youtube.svg';
import { ReactComponent as SpotifyIcon } from '../assets/images/spotify.svg';

export const Socials = () => {
  const socials = [
    {
      name: 'SoundCloud',
      url: 'https://soundcloud.com/guillermo-campollo',
      Icon: SoundcloudIcon,
    },
    {
      name: 'YouTube',
      url: 'https://www.youtube.com/@DubStudios-l3c',
      Icon: YoutubeIcon,
    },
    {
      name: 'Spotify',
      url: 'https://open.spotify.com/artist/1EZQz567kMCeTKaVLPky1Z?si=bRRXEdgXTVqAxe3Cv81FXg',
      Icon: SpotifyIcon,
    },
  ];

  return (
    <footer
      className="flex flex-col justify-center items-center w-full bg-gray-900 text-white py-10"
      id="socials-page"
    >
      <h1 className="text-2xl font-bold mb-5">Follow Us on Social Media</h1>
      <div className="flex flex-row justify-center gap-8">
        {socials.map((social) => (
          <a
            key={social.name}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center text-center hover:text-yellow-400 transition"
          >
            <social.Icon className="w-8 h-8 mb-2" />
            <span className="text-base font-medium">{social.name}</span>
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Socials;
